import styled from 'styled-components';

import { colors } from '../../../assets/colors';

export const Container = styled.button`
  background: ${colors.purple};
  border-radius: 20px;
  border-style: none;
  color: ${colors.white};
  font-weight: bold;
  height: 40px;
  padding: 0 20px 0 20px;
  width: auto;
`;

import React from 'react';
import { Carousel as CarouselResponsive } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function Carousel({ children }) {
  return (
    <CarouselResponsive showArrow={false} showThumbs={false} showStatus={false}>
      {children}
    </CarouselResponsive>
  );
}

export default Carousel;

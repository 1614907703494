import styled from 'styled-components';

import { colors } from '../../../assets/colors';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    border-style: solid;
    padding-bottom: 15px;
    padding-right: 15px;
    padding-top: 15px;

    &:first-child {
      align-items: center;
      border-color: ${colors.gray['100']};
      border-left-style: none;
      border-width: 1px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &:nth-child(2) {
      align-items: center;
      border-color: ${colors.gray['100']};
      border-right-style: none;
      border-width: 1px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 0;
      width: 100%;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 10px;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    margin: 0;
  }
`;

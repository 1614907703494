import React from 'react';

import { ReactComponent as SuperCar } from '../../../assets/svg/supercar.svg';
import { ReactComponent as SuperCarIcon } from '../../../assets/svg/supercar_icon.svg';
import mokup from '../../../assets/images/mokup.png';
import {
  Container,
  ContainerStores,
  ContainerSuperCarIcon,
  Text,
  Mokup,
  AppleStoreIcon,
  GoogleStoreIcon,
} from './styled';

function Footer() {
  const doRedirect = (link) => {
    window.location.href = link;
  };

  return (
    <Container>
      <SuperCar />
      <Text>Seu novo jeito de comprar e vender carro.</Text>
      <Mokup src={mokup} />
      <ContainerSuperCarIcon>
        <SuperCarIcon />
      </ContainerSuperCarIcon>
      <Text>Baixe o app.</Text>
      <ContainerStores>
        <AppleStoreIcon
          onClick={() => doRedirect(process.env.REACT_APP_STORE_IOS_URL)}
        />
        <GoogleStoreIcon
          onClick={() => doRedirect(process.env.REACT_APP_STORE_ANDROID_URL)}
        />
      </ContainerStores>
    </Container>
  );
}

export default Footer;

import React from 'react';
import { Container } from './styled';

function Error() {
  return (
    <Container>
      <h3>Esse anúncio não foi encontrado</h3>
    </Container>
  );
}

export default Error;

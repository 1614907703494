import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0 40px;
  padding: 50px 20px 0 20px;

  h3 {
    font-size: 30px;
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 10px;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
  }
`;

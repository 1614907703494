import React from 'react';

import { Container, ResponsiveContainer } from './styled';

function Layout({ children }) {
  return (
    <Container>
      <ResponsiveContainer>{children}</ResponsiveContainer>
    </Container>
  );
}

export default Layout;

import React, { useContext } from 'react';

import { LandingContext } from '../../../contexts/Landing';
import { Container } from './styled';

function Store() {
  const { advert } = useContext(LandingContext);

  return (
    <Container>
      <h3>
        {advert?.user?.store
          ? advert?.user?.store?.name
          : advert?.user?.client?.name}
      </h3>
      <h5>{advert?.user?.store ? 'Loja' : 'Pessoa Física'}</h5>
    </Container>
  );
}

export default Store;

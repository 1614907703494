import React, { useContext } from 'react';

import Collapse from '../../atoms/Collapse';
import { LandingContext } from '../../../contexts/Landing';
import { Container } from './styled';

function CarAbout() {
  const { advert } = useContext(LandingContext);

  return (
    <Collapse title={'Sobre o veículo'}>
      <Container>
        <span>{advert?.description}</span>
      </Container>
    </Collapse>
  );
}

export default CarAbout;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    font-size: 30px;
    font-weight: 900;
    line-height: 35px;
    margin: 0;
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  img {
    height: 70px;
  }
`;

export const ContainerText = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

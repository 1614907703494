import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import reportWebVitals from './reportWebVitals';
import Landing from './components/pages/Landing';
import './index.css';
import 'typeface-roboto';

Sentry.init({
  dsn: process.env.REACT_APP_DSN_SENTRY,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Landing />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

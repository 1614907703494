import React, { useEffect, useState } from 'react';

import Layout from '../../molecules/Layout';
import Redirect from '../../molecules/Redirect';
import Store from '../../molecules/Store';
import Gallery from '../../molecules/Gallery';
import CarName from '../../molecules/CarName';
import { ContainerCarInfo } from './styled';
import CarPrice from '../../molecules/CarPrice';
import CarYearKM from '../../molecules/CarYearKM';
import CarDoorColor from '../../molecules/CarDoorColor';
import CarTransmissionTorque from '../../molecules/CarTransmissionTorque';
import CarArmoredPlate from '../../molecules/CarArmoredPlate';
import CarItem from '../../molecules/CarItem';
import CarAbout from '../../molecules/CarAbout';
import Footer from '../../molecules/Footer';
import { getById } from '../../../services/Advert';
import { getLastRouteParam, getParam } from '../../../utils/Url';
import { LandingContext } from '../../../contexts/Landing';
import Error from '../../molecules/Error';

function Landing() {
  const [advert, setAdvert] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    load();
  }, [setAdvert, setError]);

  const load = async () => {
    try {
      const advertId = getLastRouteParam();
      const response = await getById(advertId);
      setAdvert(response.data);
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Layout>
      <LandingContext.Provider
        value={{
          advert,
        }}
      >
        <Redirect />
        {!error ? (
          <>
            <Store />
            <Gallery />
            <ContainerCarInfo>
              <CarName />
              <CarPrice />
              <CarYearKM />
              <CarDoorColor />
              <CarTransmissionTorque />
              <CarArmoredPlate />
            </ContainerCarInfo>
            <CarItem />
            <CarAbout />
          </>
        ) : (
          <Error />
        )}
        <Footer />
      </LandingContext.Provider>
    </Layout>
  );
}

export default Landing;

import React, { useContext } from 'react';

import TwoText from '../../atoms/TwoText';
import { LandingContext } from '../../../contexts/Landing';
import { capitalize } from '../../../utils/Transform';

function CarDoorColor() {
  const { advert } = useContext(LandingContext);

  return (
    <TwoText
      titleOne={'Portas'}
      valueOne={advert?.doors}
      titleTwo={'Cor'}
      valueTwo={capitalize(advert?.color)}
    />
  );
}

export default CarDoorColor;

import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styled';

function TwoText({ titleOne, valueOne, titleTwo, valueTwo }) {
  return (
    <Container>
      <div>
        <h3>{titleOne}</h3>
        <h4>{valueOne}</h4>
      </div>
      <div>
        <h3>{titleTwo}</h3>
        <h4>{valueTwo}</h4>
      </div>
    </Container>
  );
}

TwoText.propTypes = {
  titleOne: PropTypes.string,
  valueOne: PropTypes.string,
  titleTwo: PropTypes.string,
  valueTwo: PropTypes.string,
};

export default TwoText;

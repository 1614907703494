import styled from 'styled-components';

import { colors } from '../../../assets/colors';

export const Container = styled.div`
  border-top-color: ${colors.gray['100']};
  border-top-style: solid;
  border-top-width: 1px;
  padding: 20px 20px 0;
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    margin: 0;
  }
`;

import React, { useState } from 'react';

import Button from '../../atoms/Button';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { Container } from './styled';

function Redirect() {
  const [close, setClose] = useState(false);

  const options = {
    android: {
      text: 'SuperCar app para Android',
      link: process.env.REACT_APP_STORE_ANDROID_URL,
    },
    ios: {
      text: 'SuperCar app para IOS',
      link: process.env.REACT_APP_STORE_IOS_URL,
    },
  };
  let device = null;

  if (navigator.userAgent.toLowerCase().includes('android')) {
    device = 'android';
  }

  if (
    navigator.userAgent.toLowerCase().includes('iphone') ||
    navigator.userAgent.toLowerCase().includes('ipad')
  ) {
    device = 'ios';
  }

  const doRedirect = () => {
    window.location.href = options[device].link;
  };

  if (!device || close) {
    return null;
  }

  return (
    <Container>
      <div>
        <CloseIcon onClick={() => setClose(true)} />
        <h6>{options[device].text}</h6>
      </div>
      <Button text={'INSTALAR'} onClick={doRedirect} />
    </Container>
  );
}

export default Redirect;

import styled from 'styled-components';

import { ReactComponent as AppleStore } from '../../../assets/svg/apple_store.svg';
import { ReactComponent as GoogleStore } from '../../../assets/svg/google_store.svg';
import { colors } from '../../../assets/colors';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 64px;
`;

export const Text = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  margin-top: 20px;
  text-align: center;
  width: 60%;
`;

export const Mokup = styled.img`
  height: auto;
  margin: 30px 0 60px;
  width: 100%;
`;

export const ContainerSuperCarIcon = styled.div`
  -webkit-box-shadow: -8px 15px 50px -4px rgba(0, 0, 0, 0.23);
  align-items: center;
  background: ${colors.white};
  border-radius: 15px;
  box-shadow: -8px 15px 50px -4px rgba(0, 0, 0, 0.23);
  display: flex;
  height: 71px;
  justify-content: center;
  width: 71px;
`;

export const ContainerStores = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 0 50px;
`;

const StoreIcon = `
  margin-right: 10px;
  width: 115px;
`;

export const AppleStoreIcon = styled(AppleStore)`
  ${StoreIcon}
`;

export const GoogleStoreIcon = styled(GoogleStore)`
  ${StoreIcon}
`;

import React, { useContext } from 'react';

import Carousel from '../../atoms/Carousel';
import { LandingContext } from '../../../contexts/Landing';
import ImageError from '../../../assets/images/image_error.png';
import { ImageGallery } from './styled';

function Gallery() {
  const { advert } = useContext(LandingContext);

  return (
    <Carousel>
      {advert?.pictures?.map((picture) => (
        <ImageGallery
          key={picture.id}
          src={picture.url}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = ImageError;
          }}
        />
      ))}
    </Carousel>
  );
}

export default Gallery;

import React, { useContext } from 'react';

import Collapse from '../../atoms/Collapse';
import { LandingContext } from '../../../contexts/Landing';
import { Grid, GridItem } from './styled';

function CarItem() {
  const { advert } = useContext(LandingContext);
  const itemsCar = [advert?.extras, advert?.optionals].flat(1).filter((v) => v);

  return (
    <Collapse title={'Ítens do veículo'}>
      <Grid>
        {itemsCar.map((item, index) => (
          <GridItem key={index}>{item}</GridItem>
        ))}
      </Grid>
    </Collapse>
  );
}

export default CarItem;

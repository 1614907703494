import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;

  h3 {
    color: ${colors.purple};
    font-size: 20px;
  }
`;

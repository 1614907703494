import styled from 'styled-components';

import { colors } from '../../../assets/colors';

export const Container = styled.div`
  align-items: center;
  background: ${colors.white};
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-around;
  width: 100%;

  div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-left: 20px;
    width: 100%;

    h6 {
      font-size: 14px;
      font-weight: 400;
      margin-left: 20px;
    }
  }

  span {
    font-size: 20px;
  }

  button {
    margin-right: 20px;
  }
`;

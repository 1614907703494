import React, { useContext } from 'react';

import TwoText from '../../atoms/TwoText';
import { LandingContext } from '../../../contexts/Landing';

function CarTransmissionTorque() {
  const { advert } = useContext(LandingContext);

  return (
    <TwoText
      titleOne={'Câmbio'}
      valueOne={advert?.gearType}
      titleTwo={'Tanque'}
      valueTwo={advert?.fuelType}
    />
  );
}

export default CarTransmissionTorque;

import styled from 'styled-components';

import { colors } from '../../../assets/colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ResponsiveContainer = styled.div`
  background: ${colors.gray['50']};
  height: 100%;
  max-width: 400px;
  width: 100%;
`;

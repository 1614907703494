import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 30px;

  span {
    font-size: 17px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 20px;
  }
`;

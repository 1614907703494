import styled from 'styled-components';

import { colors } from '../../../assets/colors';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  div:nth-child(even) {
    border-right-style: none;
  }

  div:nth-child(odd) {
    border-left-style: none;
    padding-left: 0;
  }
`;

export const GridItem = styled.div`
  border-color: ${colors.gray['100']};
  border-style: solid;
  border-width: 1px;
  padding: 20px;
`;

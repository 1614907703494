import React, { useContext } from 'react';

import { LandingContext } from '../../../contexts/Landing';
import { Container, ContainerText } from './styled';

function CarName() {
  const { advert } = useContext(LandingContext);

  return (
    <Container>
      <ContainerText>
        <h3>{advert?.model?.name}</h3>
        <h5>{advert?.model?.version}</h5>
      </ContainerText>
      <img src={advert?.model?.brand?.logo} />
    </Container>
  );
}

export default CarName;

import React, { useContext } from 'react';

import TwoText from '../../atoms/TwoText';
import { LandingContext } from '../../../contexts/Landing';
import { numberWithDots } from '../../../utils/Mask';

function CarYearKM() {
  const { advert } = useContext(LandingContext);

  return (
    <TwoText
      titleOne={'Ano'}
      valueOne={advert?.year}
      titleTwo={'KM'}
      valueTwo={numberWithDots(advert?.km)}
    />
  );
}

export default CarYearKM;

import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styled';

function Button({ text, onClick }) {
  return <Container onClick={onClick}>{text}</Container>;
}

export default Button;

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

import React, { useContext } from 'react';

import TwoText from '../../atoms/TwoText';
import { LandingContext } from '../../../contexts/Landing';

function CarArmoredPlate() {
  const { advert } = useContext(LandingContext);

  return (
    <TwoText
      titleOne={'Blindado'}
      valueOne={
        advert?.optionals?.find((v) => v === 'Blindado') ? 'Sim' : 'Não'
      }
      titleTwo={'Placa'}
      valueTwo={`Final ${advert?.finalPlate}`}
    />
  );
}

export default CarArmoredPlate;

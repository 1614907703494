export const colors = {
  black: '#000000',
  shadow: '#000000',
  pink: '#de10ff',
  purple: '#8c0aff',
  lightPurple: '#b10aff',
  purpleGrape: '#25083e',
  white: '#ffffff',
  shade: '#00000046',

  gray: {
    50: '#f7f7f7',
    100: '#e7e7e7',
    200: '#d8d8d8',
    400: '#a9a9a9',
    500: '#818185',
    700: '#393939',
  },

  loadingGradiente: {
    start: '#de10ff',
    finish: '#4e10ff',
  },

  toast: {
    info: '#393939d9',
    success: '#3e2c69d9',
    error: '#e4432dd9',
  },

  storesGradiente: {
    tones: ['#4e10ff', '#6007af'],
    css: 'linear-gradient(180deg, #4e10ff 0%, #de10ff 100%);',
  },

  menuGradiente: {
    tones: ['#000000', '#de10ff'],
    css: 'linear-gradient(180deg, #000000 0%, #6007af 51.04%, #db00ff 100%);',
  },

  bottomGradiente: {
    tones: ['#5210FF', '#D310FF'],
    css: 'linear-gradient(180deg, #5210FF 0%, #D310FF 100%);',
  },

  carColors: {
    BRANCA: '#ffffff',
    PRETA: '#000000',
    PRATA: '#d8d8d8',
    CINZA: '#7c7c7c',
    AZUL: '#4e10ff',
    VERMELHA: '#f4002c',
    MARROM: '#823b14',
    VERDE: '#225719',
    LARANJA: '#dd6a00',
  },
};

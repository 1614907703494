import React, { useContext } from 'react';

import { LandingContext } from '../../../contexts/Landing';
import { numberWithDots } from '../../../utils/Mask';
import { Container } from './styled';

function CarPrice() {
  const { advert } = useContext(LandingContext);

  return (
    <Container>
      <h5>Valor</h5>
      <h3>R${numberWithDots(advert?.price)}</h3>
    </Container>
  );
}

export default CarPrice;

import styled from 'styled-components';

import { colors } from '../../../assets/colors';

export const Container = styled.div`
  align-items: center;
  border-color: ${colors.gray['100']};
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 20px;
  padding-top: 15px;

  h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
  }

  h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    margin: 0;
  }
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse as CollapseReact } from 'react-collapse';

import { ReactComponent as ExpandMore } from '../../../assets/svg/expand_more.svg';
import { ReactComponent as ExpandLess } from '../../../assets/svg/expand_less.svg';
import { colors } from '../../../assets/colors';
import { Container, HeaderContainer } from './styled';

function Collapse({ children, title }) {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <HeaderContainer>
        <h5>{title}</h5>
        {open ? (
          <ExpandLess onClick={() => setOpen(!open)} fill={colors.purple} />
        ) : (
          <ExpandMore onClick={() => setOpen(!open)} fill={colors.purple} />
        )}
      </HeaderContainer>
      <CollapseReact isOpened={open}>{children}</CollapseReact>
    </Container>
  );
}

Collapse.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Collapse;
